.testimonials {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 0;
}
.t-wrapper {
  display: grid;
  align-items: flex-end;
  width: 100%;
  grid-template-columns: 1fr 2fr 1fr;
}
@media (max-width:425px) {
  .testimonials{
    padding: 4rem 1.5rem !important;
  }
  .t-wrapper{
    grid-template-columns: 1fr !important;
  }
  .text-container{
    text-align: center !important;
    display: flex !important;
    flex-direction: column !important;
    gap: 1rem !important;
  }
  .t-hero-img{
    width: 80vw !important;
  }
  .tst-caousal{
    padding: 2rem !important;
  }
}
.t-hero-img {
  width: 25rem;
  justify-self: center;
}
.text-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.text-container > :nth-child(1) {
  font-size: 2rem;
  font-weight: 700;
  display: block;
  text-transform: uppercase;
}
.text-container > :nth-child(2) {
  font-size: 0.8rem;
  display: block;
  text-transform: uppercase;
}
.t-wrapper > :nth-child(3) {
  text-align: right;
}
.reviews {
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
}
.t-carousal {
  width: 100%;
}
.swiper-pagination{
   margin-top: 1rem !important;
}
.tst-caousal {
  padding: 2rem;
}
.testimonial-slider {
  align-items: center;
  background: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 1rem 3rem -50px #7d7d7d;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  padding: 1rem;
  position: relative;
}
.testimonial-slider img {
  width: 3rem;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
}
.testimonial-slider > :nth-child(2) {
  font-size: 0.8rem;
  letter-spacing: 1px;
  margin-top: 2rem;
  text-align: center;
}
.testimonial-slider hr{
  display: block;
  unicode-bidi: isolate;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  margin-inline-start: auto;
  margin-inline-end: auto;
  overflow: hidden;
  background: #333;
  border: none;
  height: 2px;
  width: 80%;
}
.testimonial-slider > :nth-child(4) {
  font-weight: 500;
  font-size: 1rem;
}
