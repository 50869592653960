.product-wrapper {
  margin-top: 3rem;
  padding: 0 2rem;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
}
@media (max-width: 850px) {
  .product-wrapper {
    padding: 0 !important;
    position: relative;
    gap: 0 !important;
  }
  .product-wrapper > :nth-child(1) {
    position: absolute;
    width: 5rem !important;
    left: 13% !important;
    top: -4rem !important;
  }
  .menu {
    padding-left: 0px !important;
  }
  .products {
    display: flex !important;
    flex-direction: column !important;
  }

  ul {
    display: flex;
    flex-direction: row !important;
    font-weight: 500;
    font-size: 1.3rem;
  }
  li {
    margin: 0px !important;
  }
  li:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100%;
    border-top: 2px solid #262626;
    border-bottom: 2px solid #262626;
    transform: scaleY(2);
    opacity: 0;
    transition: 0.6s;
  }
  li:after {
    content: "";
    position: absolute;
    top: 2px;
    left: 0;
    width: 100% !important;
    height: 100%;
    background-color: #262626;
    transform: scale(0);
    opacity: 0;
    transition: 0.6s;
    z-index: -1;
  }
}
.product-wrapper > :nth-child(1) {
  position: absolute;
  width: 8rem;
  left: 25%;
  top: -6rem;
}
.products {
  display: grid;
  width: 90%;
  grid-template-columns: 25% auto;
}

ul {
  /* position: absolute; */
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 1.3rem;
}
li {
  list-style: none;
}
li {
  position: relative;
  display: block;
  text-transform: uppercase;
  margin: 20px 0;
  padding: 10px 20px;
  text-decoration: none;
  color: #262626;
  font-family: sans-serif;
  font-size: 18px;
  font-weight: 600;
  transition: 0.6s;
  z-index: 1;
}
li:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 70%;
  height: 100%;
  border-top: 2px solid #262626;
  border-bottom: 2px solid #262626;
  transform: scaleY(2);
  opacity: 0;
  transition: 0.6s;
}
li:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 0;
  width: 70%;
  height: 100%;
  background-color: #262626;
  transform: scale(0);
  opacity: 0;
  transition: 0.6s;
  z-index: -1;
}
@media (max-width: 425px) {
  ul {
    display: flex;
    flex-direction: column !important;
    font-weight: 500;
    font-size: 1.3rem;
  }
  li {
    position: relative;
    display: block;
    text-transform: uppercase;
    margin: 20px 0;
    padding: 10px 20px;
    text-decoration: none;
    color: #262626;
    font-family: sans-serif;
    font-size: 18px;
    font-weight: 600;
    transition: 0.6s;
    z-index: 1;
  }
  li:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 70% !important;
    height: 100%;
    border-top: 2px solid #262626;
    border-bottom: 2px solid #262626;
    transform: scaleY(2);
    opacity: 0;
    transition: 0.6s;
  }
  li:after {
    content: "";
    position: absolute;
    top: 2px;
    left: 0;
    width: 70% !important;
    height: 100%;
    background-color: #262626;
    transform: scale(0);
    opacity: 0;
    transition: 0.6s;
    z-index: -1;
  }
  .product-wrapper > :nth-child(1) {
    position: absolute;
    width: 5rem !important;
    left: 4% !important;
    top: -4rem !important;
  }
}
li:hover {
  color: #fff;
}
li:hover::before {
  transform: scaleY(1);
  opacity: 1;
}
li:hover::after {
  transform: scaleY(1);
  opacity: 1;
}
.lists {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  height: 25rem;
  overflow-y: scroll;
  grid-gap: 2rem;
  justify-content: space-between;
}
.product {
  width: 15rem;
  height: 10rem;
  background: white;
  position: relative;
  overflow: hidden;
  padding: 1rem;
  display: flex;
  border-radius: 1rem;
}
.product-image {
  transform: rotate(-20deg);
  position: absolute;
  right: 0%;
  top: 32%;
  width: 5rem;
  height: 10rem;
}
