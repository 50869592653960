.slider-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 10%;
  margin-top: 2rem;
}
.slider-container .swiper {
  width: 70%;
  height: 12rem;
  position: unset !important;
}
.slider-container .swiper-slide {
  background: white;
  display: flex;
  border-radius: 10px;
  padding: 1rem;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
}
.slider-container .swiper-button-prev {
  left: 6rem;
  color: black;
  /* display: none; */
}
.slider-container .swiper-button-next {
  right: 6rem;
  color: black;
  /* display: none; */
}
.left-s {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.name {
  display: flex;
  flex-direction: column;
}
.name > :first-child {
  font-size: 1.4rem;
  font-weight: 600;
}
.name > :nth-child(2) {
  font-size: 0.7rem;
}
.left-s > :nth-child(2) {
  font-weight: bold;
  font-size: 2rem;
}
.left-s > :nth-child(3) {
  border: 1px solid var(--black);
  padding: 5px 10px;
  width: max-content;
  border-radius: 15px;
  font-size: 0.8rem;
}
.image-product {
  transform: rotate(-20deg);
  position: absolute;
  right: 0;
  width: 40%;
  height: 100%;
  bottom: -20%;
}
@media (max-width: 865px) {
  .slider-container .swiper {
    width: 90%;
    height: 10rem;
  }
  .slider-container .swiper-button-prev,
  .slider-container .swiper-button-next {
    display: none;
  }
}
@media (max-width: 425px) {
  .slider-container .swiper {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 10rem;
  }
}
.slider-container-swiper .swiper-pagination {
  bottom: 8px;
  top: auto;
  left: 0;
  width: 100%;
}
